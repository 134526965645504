import logo from './logo-bianco.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Andrea Zucca</h1>
        <a className="spacer" href="mailto:andrea.zucca@stackhouse.it">andrea.zucca@stackhouse.it</a>
        <a className="spacer" href="tel:+393398814989">+39 339 881 4989</a>
        <br />

        <h3>Stackhouse Srl</h3>
        <h4>PIVA: 03761740921</h4>

        <div className="btn-save">
          <a href="/andreazucca.vcf">SAVE CONTACT</a>
        </div>

      </header>
    </div>
  );
}

export default App;
